import React from 'react';
import { graphql, navigate } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Layout from '../layouts/layout';
import Project from '../models/Project';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import ICON_ARROW_RIGHT from '../images/icon-arrow-right.png';
import './ProjectDetails.scss';
// import ImageGallery from '../components/ImageGallery/ImageGallery';

interface Props {
  data: {
    project: Project;
    prev: Project | null;
    next: Project | null;
  };
}

const ProjectDetails = ({ data: { project, prev, next } }: Props): JSX.Element => {
  return (
    <Layout>
      <div className="project-details">
        <Header />
        <div className="project-details-container">
          <div className="project-details-header">
            <p className="project-details-group" data-sal="slide-right" data-sal-duration="600">
              {project.group}
            </p>
            <h1 data-sal="slide-right" data-sal-duration="600">
              {project.title}
            </h1>
            {/* <p className="project-details-services" data-sal="slide-right" data-sal-duration="600">
              {`Services: ${project.services}`}
            </p>
            <p className="project-details-date" data-sal="slide-right" data-sal-duration="600">
              {`Date: ${project.date}`}
            </p> */}
          </div>
          {/* <div className="project-details-gallery">
            {project.images.filter((i) => i).length > 0 ? <ImageGallery images={project.images} /> : <p>No images</p>}
          </div>
          <div className="project-details-content">
            <h2 data-sal="zoom" data-sal-duration="300">
              {project.subtitle}
            </h2>
            {project.paragraphs.map((p, i: number) => (
              <>
                <p key={`project-paragraph-${i + 1}`} data-sal="slide-up" data-sal-duration="600">
                  {p}
                </p>
                {project.banners[i] ? (
                  <Img
                    className="project-details-banner"
                    fluid={(project.banners[i] as { childImageSharp: { fluid: FluidObject } }).childImageSharp.fluid}
                    alt=""
                    data-sal="slide-up"
                    data-sal-duration="600"
                  />
                ) : null}
              </>
            ))}
          </div> */}
          <div
            className="project-img-container"
            style={{ backgroundColor: project.background }}
            data-sal="slide-up"
            data-sal-duration="600"
          >
            {project.images.map((img, i) =>
              img ? (
                <GatsbyImage
                  image={img.childImageSharp.gatsbyImageData}
                  key={`image-${i + 1}`}
                  className="project-img"
                  alt={project.title}
                />
              ) : (
                <div className="project-img" key={`image-${i + 1}`} />
              ),
            )}
          </div>
          <div className="project-details-lists">
            {(project.requirements?.length || 0) > 0 && (
              <div className="project-details-column">
                <p>Requirements:</p>
                <ul>
                  {project.requirements.map((r, i) => (
                    <li key={`req-${i + 1}`}>{r}</li>
                  ))}
                </ul>
              </div>
            )}
            {(project.deliver?.length || 0) > 0 && (
              <div className="project-details-column">
                <p>How we delivered:</p>
                <ul>
                  {project.deliver.map((d, i) => (
                    <li key={`del-${i + 1}`}>{d}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          {prev && (
            <button
              type="button"
              className="home-more-button mod-prev"
              onClick={() => {
                navigate(`/work/${prev.projectId}`);
              }}
              data-sal="fade"
              data-sal-duration="600"
            >
              <div>
                <p>Previous Project</p>
                <img src={ICON_ARROW_RIGHT} alt="" />
              </div>
            </button>
          )}

          {next && (
            <button
              type="button"
              className="home-more-button"
              onClick={() => {
                navigate(`/work/${next.projectId}`);
              }}
              data-sal="fade"
              data-sal-duration="600"
            >
              <div>
                <p>Next Project</p>
                <img src={ICON_ARROW_RIGHT} alt="" />
              </div>
            </button>
          )}
        </div>
        <Footer />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($projectId: String!, $prevId: String, $nextId: String) {
    project: projectsJson(projectId: { eq: $projectId }) {
      group
      title
      background
      images {
        childImageSharp {
          gatsbyImageData(quality: 90, layout: FULL_WIDTH)
        }
      }
      requirements
      deliver
    }
    prev: projectsJson(projectId: { eq: $prevId }) {
      projectId
    }
    next: projectsJson(projectId: { eq: $nextId }) {
      projectId
    }
  }
`;

export default ProjectDetails;
